import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import '../styles/styles.css'

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Arlem Interior Design</title>
      <div>
        <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 font-gentium">
          <div class="text-center">
            <StaticImage
              src="../images/arlem.jpg"
              loading="eager"
              width={600}
              quality={80}
              formats={["auto", "webp", "jpg"]}
              alt="Arlem logo"
              objectFit="fill"
            />
            <h3 className="font-bold text-3xl mt-8 text-stone-800">Coming soon...</h3>
            <h3 className="text-xl mt-4 text-stone-800">
              View our <a href="https://www.instagram.com/arleminteriordesign" target="_blank" rel="noreferrer" className="font-bold text-[#Cfa78e] hover:underline">Instagram</a> or <a href="https://arlem.design/message-us" className="font-bold text-[#Cfa78e] hover:underline">message us here</a>.
            </h3>
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
